import React, { Component } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";

let timer;

export default class Header extends Component {
  toggleMenuLocal = () => {
    this.props.menuToggle();
    timer = setTimeout(
      () => {
        if (this.props.menuIsHidden) {
          this.refs.nav.style.display = "none";
        } else {
          this.refs.nav.style.display = "flex";
        }
      },
      this.props.menuIsHidden ? 600 : 1200
    );
  };

  handleClick = () => {
    this.toggleMenuLocal();
  };

  componentWillUnmount() {
    clearInterval(timer);
  }

  render() {
    const { menuIsHidden } = this.props;
    return (
      <header
        className="component header"
        style={{
          height: menuIsHidden
            ? window.innerHeight > 700
              ? "16vh"
              : "20vh"
            : "100vh",
          transition: menuIsHidden ? "all .6s .6s" : "all .6s",
          boxShadow: menuIsHidden
            ? "rgba(0, 0, 0, 0.35) 12px 13px 28px 5px"
            : "0px 3px 25px 0px rgba(0,0,0,0.35)"
        }}
      >
        <div
          className={menuIsHidden ? "btn-menu" : "btn-menu btn-menu_active"}
          onClick={this.toggleMenuLocal}
        >
          <div className="line line_1" />
          <div className="line line_2" />
          <div className="line line_3" />
        </div>

        <Link to="/" className="logo" />

        <nav
          ref={"nav"}
          className="header__menu"
          style={{
            opacity: menuIsHidden ? 0 : 1,
            transition: menuIsHidden ? "all .6s" : "all .3s .6s"
          }}
        >
          <Link to="/menu">
            <span
              className="header__menu__menu-item menu-item menu-item__1"
              onClick={this.handleClick}
            >
              Меню
            </span>
          </Link>
          <Link to="/events">
            <span
              className="header__menu__menu-item menu-item menu-item__2"
              onClick={this.handleClick}
            >
              События
            </span>
          </Link>
          <Link to="/actions">
            <span
              className="header__menu__menu-item menu-item menu-item__3"
              onClick={this.handleClick}
            >
              Акции
            </span>
          </Link>
          <Link to="/galleryMobile">
            <span
              className="header__menu__menu-item menu-item menu-item__4"
              onClick={this.handleClick}
            >
              Галерея
            </span>
          </Link>
          <Link to="/rent">
            <span
              className="header__menu__menu-item menu-item menu-item__5"
              onClick={this.handleClick}
            >
              Аренда
            </span>
          </Link>
          <Link to="/contacts">
            <span
              className="header__menu__menu-item menu-item menu-item__6"
              onClick={this.handleClick}
            >
              Контакты
            </span>
          </Link>
        </nav>
      </header>
    );
  }
}
