import React from "react";
import "./Events.scss";

const Events = () => (
  <div className="events">
    <h1>События</h1>
    <h2 className="text_title">Скоро здесь появятся наши события!</h2>

  </div>
);

export default Events;
