import React, { Component } from "react";
import "./Actions.scss";
import { actions } from "../../data/main.json";

export default class Actions extends Component {
  state = {
    bottom: false
  };

  handleScroll = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      this.setState({
        bottom: true
      });
    } else {
      this.setState({
        bottom: false
      });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { bottom } = this.state;
    return (
      <div className="actions">
        <h1>Акции</h1>
        <div className="action">
          <div className="action_text">
            <h2 className="text_title">Доставка</h2>
            <p className="text_content">
              Теперь, вы можете наслаждаться нашей кухней  не выходя из дома или офиса.<br/>
              Мы привезем ваш заказ, в любую точку Красногорска.<br/>
              Заказы принимаются с 12:00 да 22:30.<br/>
              Минимальная сумма заказа составляет 1000 руб.<br/>
              Телефон для доставки: <br/>
              <a href="tel:+74955637641"> +7 495 563 76 41</a>
        <br />
            </p>
          </div>
        </div>        
        <div className="action">
          <div className="action_text">
            <h2 className="text_title">Караоке</h2>
            <p className="text_content">
              Каждую пятницу и субботу с 22.00 до 01.00 у Вас есть возможность исполнить свои любимые хиты<br/>
              Депозит составляет 2000 руб. за одного человека
              <br/>
              Просим вас предварительно связаться с менеджером для бронирования
            </p>
          </div>
        </div>
        {actions.map((el, i) => (
          <div className="action" key={i}>
            <div className="action_text">
              <h2 className="text_title">{el.title}</h2>
              <p className="text_content">{el.content}</p>
            </div>
            <div
              className="action_image"
              style={{
                background: `url(./img/actions/${i + 1}.jpg)`,
                backgroundSize: "cover"
              }}
            />
          </div>
        ))}
        <div className="action">
          <div className="action_text">
            <h2 className="text_title">Бонусы для постоянных клинетов</h2>
            <p className="text_content">
              Для постоянных гостей в нашем кафе предусмотрена бонусная
              программа. Всю информацию о картах, Вы можете получить у
              администратора или любого сотрудника
            </p>
          </div>
        </div>
        <div className="shadow" style={{ opacity: bottom ? 0 : 1 }}>
          <div className="arrow" />
        </div>
      </div>
    );
  }
}
