import React from "react";
import "./Main.scss";
import { about } from "../../data/main.json";

const Main = () => (
  <div className="main">
    <div className="pics">
      <div className="pic" />
    </div>
    <div className="info">
      <p>{about}</p>
      
      <p>Теперь в нашем кафе доступен сервис доставки, а также караоке.<br/>Подробности в разделе Акции</p>
    </div>
    <div className="adress_grey">
      <p>
        г. Красногорск
        <br />
        ул. Пионерская, д. 12А
        <br />
        <a href="tel:+74955637641"> +7 495 563 76 41</a>
      </p>
    </div>
  </div>
);

export default Main;
