import React, { Component } from "react";
import "./Menu.scss";

import { FOOD_MENU } from './data';
export default class Menu extends Component {
  state = {
    menuState: 0,
    bottom: false
  };

  handleState = e => {
    const menuState = +e.target.dataset.state;
    console.log(menuState);
    this.setState({ menuState });
  };

  handleScroll = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      this.setState({
        bottom: true
      });
    } else {
      this.setState({
        bottom: false
      });
    }
  };

  componentDidMount() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { menuState, bottom } = this.state;
    return (
      <section className="component menu">
        <nav className="menu_toggle">
          <p
            className={`${menuState === 0 ? "active" : ""}`}
            data-state="0"
            onClick={this.handleState}
          >
            Основное меню
          </p>
          <p
            className={`${menuState === 1 ? "active" : ""}`}
            data-state="1"
            onClick={this.handleState}
          >
            Напитки
          </p>
        </nav>
        <div className="decoration_line">
          <div
            className="bold_line"
            style={{
              marginLeft: menuState === 0 ? "0" : "52vw",
              width: menuState === 0 ? "29vw" : "23vw"
            }}
          />
        </div>
        {menuState === 0 && (
          <div className="section-wrapper main-menu">
            {FOOD_MENU.main.map((el, i) => (
              <div className="dish-section" key={i}>
                <p className="name">{el.name}</p>
                <div className="dishes">
                  {el.dishes.map((dish, index) => (
                    <div className="dish-name" key={index}>
                      <span className="span-dish-name">{dish.name}</span>
                      <span className="span-dish-price">{dish.price}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        {menuState === 1 && (
          <div className="section-wrapper alco-menu">
            {FOOD_MENU.drinks.map((el, i) => (
              <div className="dish-section" key={i}>
                <p className="name">{el.name}</p>
                <div className="dishes">
                  {el.drink.map((bottle, index) => (
                    <div className="dish-name" key={index}>
                      <span className="span-dish-name">{bottle.name}</span>
                      <span className="span-dish-price">{bottle.price}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="shadow" style={{ opacity: bottom ? 0 : 1 }}>
          <div className="arrow" />
        </div>
      </section>
    );
  }
}
