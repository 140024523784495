import React from "react";
import "./Rent.scss";
import { banquets } from "../../data/main.json";

const Rent = () => (
  <div className="rent">
    <h1>Аренда</h1>
    <div>
      <div className="text_content">{banquets}</div>
      <div
        className="rent_image"
        style={{
          background: `url(./img/rent/rent.jpg)`,
          backgroundSize: "cover"
        }}
      />
    </div>
  </div>
);

export default Rent;
