import React, { Component } from "react";
import "./Gallery.scss";
import Gallery from "react-photo-gallery";

import PHOTO_SET from "./photos/index";

export default class GalleryComp extends Component {
  state = {
    bottom: false
  };

  handleScroll = () => {
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      this.setState({
        bottom: true
      });
    } else {
      this.setState({
        bottom: false
      });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { bottom } = this.state;
    return (
      <div className="gallery">
        <Gallery photos={PHOTO_SET} margin={3} columns={1} />
        <div className="shadow" style={{ opacity: bottom ? 0 : 1 }}>
          <div className="arrow" />
        </div>
      </div>
    );
  }
}
