import dsc_0165 from './dsc_0165.jpg'
import dsc_0172 from './dsc_0172.jpg'
import dsc_0224 from './dsc_0224.jpg'
import dsc_0229 from './dsc_0229.jpg'
import dsc_0245 from './dsc_0245.jpg' 
import dsc_0255 from './dsc_0255.jpg' 
import dsc_0263 from './dsc_0263.jpg'
import dsc_0288 from './dsc_0288.jpg'
import dsc_0293 from './dsc_0293.jpg' 
import dsc_0304 from './dsc_0304.jpg'
import dsc_0323 from './dsc_0323.jpg'
import dsc_0345 from './dsc_0345.jpg'
import dsc_0371 from './dsc_0371.jpg'
import dsc_0374 from './dsc_0374.jpg'
import dsc_0375 from './dsc_0375.jpg'
import dsc_0396 from './dsc_0396.jpg'
import dsc_0411 from './dsc_0411.jpg'
import dsc_0418 from './dsc_0418.jpg'
import dsc_0421 from './dsc_0421.jpg'
import dsc_0422 from './dsc_0422.jpg'
import dsc_0440 from './dsc_0440.jpg'
import dsc_0451 from './dsc_0451.jpg'
import dsc_0454 from './dsc_0454.jpg'
import dsc_0464 from './dsc_0464.jpg'
import dsc_0467 from './dsc_0467.jpg'
import dsc_0472 from './dsc_0472.jpg'
import dsc_0497 from './dsc_0497.jpg'
import dsc_0512 from './dsc_0512.jpg'
import dsc_0514 from './dsc_0514.jpg'
import dsc_0515 from './dsc_0515.jpg'
import dsc_0519 from './dsc_0519.jpg'

const imagesData = [
  dsc_0165,
  dsc_0263,
  dsc_0245,
  dsc_0255,
  dsc_0497,
  dsc_0172,
  dsc_0454,
  dsc_0345,
  dsc_0371,
  dsc_0374,
  dsc_0375,
  dsc_0411,
  dsc_0418,
  dsc_0421,
  dsc_0224,
  dsc_0512,
  dsc_0396,
  dsc_0514,
  dsc_0515,
  dsc_0229,
  dsc_0422,
  dsc_0288,
  dsc_0293,
  dsc_0304,
  dsc_0323,
  dsc_0519,
  dsc_0440,
  dsc_0451,
  dsc_0464,
  dsc_0467,
  dsc_0472,
]

const PHOTO_SET = imagesData.map(el => ({
  src: el,
  width: 1024,
  height: 681
}))

export default PHOTO_SET