import React, { Component } from "react";
import "./App.scss";
import "./style/fonts.css";
import "./style/animate.css";
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import Menu from "./components/Menu/Menu";
import Contacts from "./components/Contacts/Contacts";
import GalleryComp from "./components/Gallery/Gallery";
import Actions from "./components/Actions/Actions";
import Events from "./components/Events/Events";
import Rent from "./components/Rent/Rent";
import { Route, Switch } from "react-router-dom";

class App extends Component {
  constructor() {
    super();
    this.state = {
      menuIsHidden: true
    };
  }

  menuToggle = () => {
    this.setState({
      menuIsHidden: !this.state.menuIsHidden
    });
  };

  render() {
    return (
      <div className="App" unselectable="on">
        <div className="padding" />
        <Switch>
          <Route exact path="/" component={Main} />
          <Route path="/menu" component={Menu} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/galleryMobile" component={GalleryComp} />
          <Route path="/rent" component={Rent} />
          <Route path="/actions" component={Actions} />
          <Route path="/events" component={Events} />
        </Switch>

        <Header
          menuIsHidden={this.state.menuIsHidden}
          menuToggle={this.menuToggle}
        />
      </div>
    );
  }
}

export default App;
