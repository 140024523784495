import React from "react";
import "./Contacts.scss";
import { Map, Marker } from "yandex-map-react";

const Contacts = () => (
  <div className="contacts">
    <div className="basic-info">
      <p>
        г. Красногорск
        <br />
        ул. Пионерская, д. 12А
        <br />
        <a href="tel:+74955637641"> +7 495 563 76 41</a>        
      </p>
    </div>
    <Map
      onAPIAvailable={function() {
        console.log("API loaded");
      }}
      width={"100vw"}
      height={"250px"}
      center={[55.8255, 37.3321]}
      zoom={16}
    >
      <Marker lat={55.82555} lon={37.3321} />
    </Map>
    <div className="socials">
      <a
        target="_blank"
        href="https://www.instagram.com/veranda_krasnogorsk/"
        className="instagram"
      />
    </div>
  </div>
);

export default Contacts;
